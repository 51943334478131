body {
  font-family: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
}
@media screen and (min-width: 769px) {
  body {
    min-width: 1080px;
  }
  .sm-only {
    display: none;
  }
  header.global-header.sm {
    display: none;
  }
  header.global-header.lg {
    width: 100%;
    min-width: 1080px;
    height: 70px;
    background-color: #00897c;
    border-bottom: 3px solid #006e63;
    position: fixed;
    z-index: 1;
  }
  header.global-header.lg .inner {
    width: 100%;
    height: 100%;
    max-width: 1440px;
    min-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-left: 50px;
    padding-right: 50px;
  }
  header.global-header.lg .inner a.logo {
    display: block;
    width: 257px;
    height: 50px;
    background-image: url("../img/svg/logo_white.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
  }
  header.global-header.lg .inner ul {
    display: flex;
    flex-direction: row;
  }
  header.global-header.lg .inner ul li {
    margin-left: 45px;
  }
  header.global-header.lg .inner ul li a {
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    position: relative;
  }
  header.global-header.lg .inner ul li a:after {
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    bottom: -3px;
    transform: scaleX(0);
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    transition-property: transform;
    transform-origin: center;
  }
  header.global-header.lg .inner ul li a:hover:after {
    transform: none;
  }
  header.global-header.lg .inner ul li a.current:after {
    transform: none;
  }
  main {
    display: block;
    padding-top: 73px;
  }
  main section.intro {
    width: 100%;
  }
  main section.intro h1 {
    width: 350px;
    height: 68px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    padding-top: 50px;
  }
  main section.intro h1 figure {
    width: 100%;
    height: 100%;
    background-image: url("../img/svg/logo_green.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
  }
  main section.intro p {
    font-size: 30px;
    line-height: 1.5;
    color: #00897c;
    text-align: center;
    margin-bottom: 30px;
  }
  main section.intro .link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 48px;
    margin-bottom: 70px;
  }
  main section.intro .link a {
    display: block;
    height: 48px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
  }
  main section.intro .link a:hover {
    opacity: 0.8;
  }
  main section.intro .link a.app {
    width: 131px;
    background-image: url("../img/btn_app.png");
    margin-right: 50px;
  }
  main section.intro .link a.goo {
    width: 160px;
    background-image: url("../img/btn_goo.png");
  }
  main section.intro figure.visual {
    width: 642px;
    height: 380px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
    background-image: url("../img/top_visual.jpg");
  }
  main section.about {
    width: 100%;
    background-color: #00897c;
    padding-bottom: 50px;
    color: #ffffff;
    text-align: center;
  }
  main section.about .title {
    font-size: 36px;
    margin-bottom: 30px;
    padding-top: 50px;
  }
  main section.about .text {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  main section.about ul {
    display: flex;
    width: 760px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    align-items: center;
  }
  main section.about ul li {
    widows: 48%;
  }
  main section.about ul li:last-child .lp-link {
    margin: 20px 0;
  }
  main section.feature {
    width: 100%;
  }
  main section.feature h2 {
    font-weight: normal;
    font-size: 48px;
    color: #00897c;
    text-align: center;
    margin-bottom: 70px;
    padding-top: 50px;
  }
  main section.feature ul {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
    font-size: 0;
  }
  main section.feature ul li:nth-of-type(3n-1) {
    margin-left: 60px;
    margin-right: 60px;
  }
  main section.feature ul li:nth-of-type(1) {
    width: 280px;
    min-height: 210px;
    margin-bottom: 50px;
    display: inline-block;
    vertical-align: top;
  }
  main section.feature ul li:nth-of-type(1) figure {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon1.svg");
  }
  main section.feature ul li:nth-of-type(1) h3 {
    color: #df6200;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  main section.feature ul li:nth-of-type(1) p {
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(2) {
    width: 280px;
    min-height: 210px;
    margin-bottom: 50px;
    display: inline-block;
    vertical-align: top;
  }
  main section.feature ul li:nth-of-type(2) figure {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon2.svg");
  }
  main section.feature ul li:nth-of-type(2) h3 {
    color: #df6200;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  main section.feature ul li:nth-of-type(2) p {
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(3) {
    width: 280px;
    min-height: 210px;
    margin-bottom: 50px;
    display: inline-block;
    vertical-align: top;
  }
  main section.feature ul li:nth-of-type(3) figure {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon3.svg");
  }
  main section.feature ul li:nth-of-type(3) h3 {
    color: #df6200;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  main section.feature ul li:nth-of-type(3) p {
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(4) {
    width: 280px;
    min-height: 210px;
    margin-bottom: 50px;
    display: inline-block;
    vertical-align: top;
  }
  main section.feature ul li:nth-of-type(4) figure {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon4.svg");
  }
  main section.feature ul li:nth-of-type(4) h3 {
    color: #df6200;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  main section.feature ul li:nth-of-type(4) p {
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(5) {
    width: 280px;
    min-height: 210px;
    margin-bottom: 50px;
    display: inline-block;
    vertical-align: top;
  }
  main section.feature ul li:nth-of-type(5) figure {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon5.svg");
  }
  main section.feature ul li:nth-of-type(5) h3 {
    color: #df6200;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  main section.feature ul li:nth-of-type(5) p {
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(6) {
    width: 280px;
    min-height: 210px;
    margin-bottom: 50px;
    display: inline-block;
    vertical-align: top;
  }
  main section.feature ul li:nth-of-type(6) figure {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon6.svg");
  }
  main section.feature ul li:nth-of-type(6) h3 {
    color: #df6200;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  main section.feature ul li:nth-of-type(6) p {
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(7) {
    width: 280px;
    min-height: 210px;
    margin-bottom: 50px;
    display: inline-block;
    vertical-align: top;
  }
  main section.feature ul li:nth-of-type(7) figure {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon7.svg");
  }
  main section.feature ul li:nth-of-type(7) h3 {
    color: #df6200;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  main section.feature ul li:nth-of-type(7) p {
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(8) {
    width: 280px;
    min-height: 210px;
    margin-bottom: 50px;
    display: inline-block;
    vertical-align: top;
  }
  main section.feature ul li:nth-of-type(8) figure {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon8.svg");
  }
  main section.feature ul li:nth-of-type(8) h3 {
    color: #df6200;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  main section.feature ul li:nth-of-type(8) p {
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.screenshot {
    background-color: #afd7d3;
    padding-top: 100px;
    padding-bottom: 70px;
  }
  main section.screenshot ul.carousel {
    width: 1020px;
    height: 533px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  main section.screenshot ul.carousel li.carousel-item {
    width: 300px;
    height: 533px;
    margin-right: 60px;
    background-color: whitesmoke;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
  }
  main section.screenshot ul.carousel li.carousel-item:nth-of-type(1) {
    background-image: url("../img/screenshot1.jpg");
  }
  main section.screenshot ul.carousel li.carousel-item:nth-of-type(2) {
    background-image: url("../img/screenshot2.jpg");
  }
  main section.screenshot ul.carousel li.carousel-item:nth-of-type(3) {
    background-image: url("../img/screenshot3.jpg");
  }
  main section.screenshot ul.carousel li.carousel-item:nth-of-type(4) {
    background-image: url("../img/screenshot4.jpg");
  }
  main section.screenshot ul.carousel li.carousel-item:nth-of-type(5) {
    background-image: url("../img/screenshot5.jpg");
  }
  main section.screenshot ul.carousel li.carousel-item:nth-of-type(6) {
    background-image: url("../img/screenshot6.jpg");
  }
  main section.screenshot ul.carousel .flickity-page-dots .dot {
    width: 15px;
    height: 15px;
    background: #666666;
    opacity: 1;
  }
  main section.screenshot ul.carousel .flickity-page-dots {
    bottom: -55px;
  }
  main section.screenshot ul.carousel .flickity-page-dots .dot {
    width: 15px;
    height: 15px;
    background: #666666;
    opacity: 1;
    margin: 0 7px;
  }
  main section.screenshot ul.carousel .flickity-page-dots .is-selected {
    background-color: #ffffff;
  }
  main section.movie {
    width: 100%;
  }
  main section.movie h2 {
    font-weight: normal;
    font-size: 48px;
    color: #00897c;
    text-align: center;
    margin-bottom: 70px;
    padding-top: 50px;
  }
  main section.movie ul {
    display: flex;
    flex-wrap: wrap;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  main section.movie ul li {
    width: 23%;
    margin: 0 1% 60px;
  }
  main section.movie ul li img {
    width: 100%;
    height: auto;
    border: 1px solid #CCC;
  }
  main section.movie ul li p {
    font-size: 14px;
    margin-top: 0.5em;
    text-align: center;
    line-height: 150%;
  }
  main section.contact {
    width: 100%;
    background-color: #00897c;
    padding-bottom: 50px;
    color: #ffffff;
    text-align: center;
  }
  main section.contact .title {
    font-size: 36px;
    margin-bottom: 30px;
    padding-top: 50px;
  }
  main section.contact .text {
    font-size: 18px;
    line-height: 1.5;
  }
  main section.contact a.mail {
    display: block;
    font-size: 18px;
    line-height: 1.5;
    color: #ffffff;
    text-decoration: none;
    margin-bottom: 30px;
  }
  main section.contact a.mail:hover {
    text-decoration: underline;
  }
  main section.contact hr {
    width: 350px;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
  }
  main section.contact p.tel {
    font-size: 18px;
    color: #ffffff;
  }
  main section.contact p.tel:before {
    display: inline-block;
    content: "";
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    background-image: url("../img/svg/tel.svg");
    position: relative;
    top: 2px;
    left: -3px;
  }
  main section.terms {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    padding-bottom: 80px;
  }
  main section.terms dl {
    width: 100%;
  }
  main section.terms dl:nth-of-type(1) {
    margin-bottom: 40px;
  }
  main section.terms dl dt {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  main section.terms dl dd {
    width: 100%;
    height: 300px;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    font-size: 13px;
    line-height: 1.5;
    padding: 10px;
    overflow-y: scroll;
  }
  main section.terms dl dd h3 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  main section.terms dl dd h3.center {
    text-align: center;
  }
  main section.terms dl dd h4 {
    margin-top: 20px;
    text-decoration: underline;
    margin-bottom: 5px;
  }
  main section.terms dl dd ol {
    padding-left: 20px;
  }
  main section.terms dl dd ol > li {
    padding-left: 10px;
    margin-bottom: 5px;
  }
  main section.terms dl dd ol > li > ul {
    margin-top: 5px;
  }
  main section.terms dl dd ol > li > ul > li {
    margin-bottom: 5px;
    padding-left: 1.5em;
    text-indent: -1.3em;
  }
  main section.terms dl dd p.right {
    text-align: right;
    margin-top: 15px;
    padding-right: 10px;
  }
  main section.terms dl dd p {
    line-height: 1.75;
    margin-bottom: 20px;
  }
  main section.terms dl dd p span {
    font-size: 11px;
  }
  main section.terms dl dd p.bold {
    margin-top: 20px;
    font-weight: bold;
  }
  main section.terms dl dd p.title {
    margin-top: 5px;
    margin-bottom: 0;
  }
  main a.lp-link {
    display: block;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 15px;
    font-size: 18px;
    line-height: 1.4;
    text-decoration: none;
    color: #ffffff;
    background-color: #f49416;
    opacity: 1;
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
  }
  main a.lp-link:hover {
    opacity: 0.7;
  }
  footer {
    width: 100%;
    min-width: 100%;
    background-color: #00554c;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    color: #ffffff;
  }
  footer a {
    text-decoration: none;
    color: #ffffff;
  }
  footer a:hover {
    text-decoration: underline;
  }
}
@media screen and (max-width: 768px) {
  header.global-header.lg {
    display: none;
  }
  header.global-header.sm {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 2px solid #006e63;
    background-color: #00897c;
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    z-index: 1;
  }
  header.global-header.sm .inner {
    width: 100%;
    position: relative;
  }
  header.global-header.sm .inner a.logo {
    display: block;
    width: 153px;
    height: 30px;
    background-image: url("../img/svg/logo_white.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
    position: absolute;
    left: 0;
    top: 10px;
  }
  header.global-header.sm .inner span.btn {
    display: block;
    width: 30px;
    height: 22px;
    position: absolute;
    right: 0;
    top: 15px;
  }
  header.global-header.sm .inner span.btn:before,
  header.global-header.sm .inner span.btn:after {
    display: block;
    content: "";
    width: 30px;
    height: 2px;
    background-color: #ffffff;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 10px;
    transition-duration: 0.3s;
    transform-origin: center;
    transition-property: transform;
  }
  header.global-header.sm .inner span.btn:before {
    transform: translateY(10px) rotate(0);
  }
  header.global-header.sm .inner span.btn:after {
    transform: translateY(-10px) rotate(0);
  }
  header.global-header.sm .inner span.btn figure {
    width: 30px;
    height: 2px;
    border-radius: 5px;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    top: 10px;
    transform: scaleX(1);
    transition-duration: 0.3s;
    transform-origin: center;
    transition-property: transform;
  }
  header.global-header.sm .inner span.btn.open figure {
    transform: scaleX(0);
  }
  header.global-header.sm .inner span.btn.open:before {
    transform: translateY(0px) rotate(35deg);
  }
  header.global-header.sm .inner span.btn.open:after {
    transform: translateY(0px) rotate(-35deg);
  }
  header.global-header.sm nav.open {
    height: calc(100vh - 50px);
  }
  header.global-header.sm nav {
    width: 100vw;
    height: 0;
    background-color: #00897c;
    position: relative;
    z-index: 1;
    margin-left: -20px;
    overflow: hidden;
    top: 50px;
    transition-duration: 0.2s;
  }
  header.global-header.sm nav ul {
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
  }
  header.global-header.sm nav ul li {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ffffff;
  }
  header.global-header.sm nav ul li:first-child {
    border-top: 1px solid #ffffff;
  }
  header.global-header.sm nav ul li a {
    display: block;
    text-decoration: none;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    line-height: 50px;
  }
  main {
    width: 100%;
    padding-top: 60px;
  }
  main section.intro {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  main section.intro h1 {
    margin-bottom: 50px;
    width: 206px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
  }
  main section.intro h1 figure {
    width: 100%;
    height: 100%;
    background-image: url("../img/svg/logo_green.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
  }
  main section.intro p {
    margin-bottom: 50px;
    font-size: 18px;
    color: #00897c;
    line-height: 1.5;
    text-align: center;
  }
  main section.intro .link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 40px;
    margin-bottom: 50px;
  }
  main section.intro .link a {
    display: block;
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
  }
  main section.intro .link a.app {
    width: 110px;
    background-image: url("../img/btn_app.png");
    margin-right: 20px;
  }
  main section.intro .link a.goo {
    width: 134px;
    background-image: url("../img/btn_goo.png");
  }
  main section.intro figure.visual {
    width: 100%;
    height: calc((100vw - 40px) * 198/335);
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
    background-image: url("../img/top_visual.jpg");
  }
  main section.about {
    width: 100%;
    background-color: #00897c;
    padding-top: 50px;
    padding-bottom: 50px;
    color: #ffffff;
    text-align: center;
  }
  main section.about .title {
    font-size: 24px;
    margin-bottom: 30px;
  }
  main section.about .text {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  main section.about .lp-link {
    margin-bottom: 20px;
  }
  main section.feature {
    width: 100%;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  main section.feature h2 {
    font-weight: normal;
    font-size: 28px;
    color: #00897c;
    text-align: center;
    margin-bottom: 50px;
  }
  main section.feature ul {
    font-size: 0;
  }
  main section.feature ul li:nth-of-type(2n-1) {
    padding-right: 10px;
  }
  main section.feature ul li:nth-of-type(2n) {
    padding-left: 10px;
  }
  main section.feature ul li:nth-of-type(1) {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  main section.feature ul li:nth-of-type(1) figure {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon1.svg");
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(1) h3 {
    font-size: 14px;
    line-height: 1.5;
    color: #df6200;
    text-align: center;
    min-height: 40px;
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(1) p {
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(2) {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  main section.feature ul li:nth-of-type(2) figure {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon2.svg");
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(2) h3 {
    font-size: 14px;
    line-height: 1.5;
    color: #df6200;
    text-align: center;
    min-height: 40px;
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(2) p {
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(3) {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  main section.feature ul li:nth-of-type(3) figure {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon3.svg");
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(3) h3 {
    font-size: 14px;
    line-height: 1.5;
    color: #df6200;
    text-align: center;
    min-height: 40px;
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(3) p {
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(4) {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  main section.feature ul li:nth-of-type(4) figure {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon4.svg");
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(4) h3 {
    font-size: 14px;
    line-height: 1.5;
    color: #df6200;
    text-align: center;
    min-height: 40px;
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(4) p {
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(5) {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  main section.feature ul li:nth-of-type(5) figure {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon5.svg");
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(5) h3 {
    font-size: 14px;
    line-height: 1.5;
    color: #df6200;
    text-align: center;
    min-height: 40px;
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(5) p {
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(6) {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  main section.feature ul li:nth-of-type(6) figure {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon6.svg");
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(6) h3 {
    font-size: 14px;
    line-height: 1.5;
    color: #df6200;
    text-align: center;
    min-height: 40px;
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(6) p {
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(7) {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  main section.feature ul li:nth-of-type(7) figure {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon7.svg");
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(7) h3 {
    font-size: 14px;
    line-height: 1.5;
    color: #df6200;
    text-align: center;
    min-height: 40px;
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(7) p {
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.feature ul li:nth-of-type(8) {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  main section.feature ul li:nth-of-type(8) figure {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("../img/svg/icon8.svg");
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(8) h3 {
    font-size: 14px;
    line-height: 1.5;
    color: #df6200;
    text-align: center;
    min-height: 40px;
    margin-bottom: 15px;
  }
  main section.feature ul li:nth-of-type(8) p {
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
  }
  main section.screenshot {
    width: 100%;
    box-sizing: border-box;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #afd7d3;
  }
  main section.screenshot ul.carousel {
    width: calc(100vw - 80px);
    height: calc((100vw - 80px) * 595/335);
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  main section.screenshot ul.carousel li.carousel-item {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
    background-color: whitesmoke;
    margin-right: 30px;
  }
  main section.screenshot ul.carousel li.carousel-item:nth-of-type(1) {
    background-image: url("../img/screenshot1.jpg");
  }
  main section.screenshot ul.carousel li.carousel-item:nth-of-type(2) {
    background-image: url("../img/screenshot2.jpg");
  }
  main section.screenshot ul.carousel li.carousel-item:nth-of-type(3) {
    background-image: url("../img/screenshot3.jpg");
  }
  main section.screenshot ul.carousel li.carousel-item:nth-of-type(4) {
    background-image: url("../img/screenshot4.jpg");
  }
  main section.screenshot ul.carousel li.carousel-item:nth-of-type(5) {
    background-image: url("../img/screenshot5.jpg");
  }
  main section.screenshot ul.carousel li.carousel-item:nth-of-type(6) {
    background-image: url("../img/screenshot6.jpg");
  }
  main section.screenshot ul.carousel .flickity-page-dots {
    bottom: -45px;
  }
  main section.screenshot ul.carousel .flickity-page-dots .dot {
    width: 15px;
    height: 15px;
    background: #666666;
    opacity: 1;
    margin: 0 7px;
  }
  main section.screenshot ul.carousel .flickity-page-dots .is-selected {
    background-color: #ffffff;
  }
  main section.movie h2 {
    font-weight: normal;
    font-size: 28px;
    color: #00897c;
    text-align: center;
    margin-bottom: 50px;
    padding-top: 50px;
  }
  main section.movie ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: 15px;
    margin-right: 15px;
  }
  main section.movie ul li {
    width: 100%;
    margin-bottom: 60px;
  }
  main section.movie ul li img {
    width: 100%;
    height: auto;
    border: 1px solid #CCC;
  }
  main section.movie ul li p {
    font-size: 14px;
    margin-top: 0.5em;
    text-align: center;
    line-height: 150%;
  }
  main section.contact {
    width: 100%;
    background-color: #00897c;
    padding-top: 50px;
    padding-bottom: 50px;
    color: #ffffff;
    text-align: center;
  }
  main section.contact .title {
    font-size: 24px;
    margin-bottom: 30px;
  }
  main section.contact .text {
    font-size: 14px;
    line-height: 1.5;
  }
  main section.contact a.mail {
    display: block;
    font-size: 14px;
    line-height: 1.5;
    color: #ffffff;
    text-decoration: none;
    margin-bottom: 30px;
  }
  main section.contact hr {
    width: calc(100% - 60px);
    height: 1px;
    margin-left: auto;
    margin-right: auto;
  }
  main section.contact p.tel {
    font-size: 14px;
    color: #ffffff;
  }
  main section.contact p.tel:before {
    display: inline-block;
    content: "";
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    background-image: url("../img/svg/tel.svg");
    position: relative;
    top: 2px;
    left: -5px;
  }
  main section.terms {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  }
  main section.terms dl:nth-of-type(1) {
    margin-bottom: 50px;
  }
  main section.terms dl dt {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  main section.terms dl dd {
    width: 100%;
    height: 300px;
    overflow-y: scroll;
    border: 1px solid #cccccc;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.5;
  }
  main section.terms dl dd h3 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  main section.terms dl dd h3.center {
    text-align: center;
  }
  main section.terms dl dd h4 {
    margin-top: 20px;
    text-decoration: underline;
    margin-bottom: 5px;
  }
  main section.terms dl dd ol {
    padding-left: 20px;
  }
  main section.terms dl dd ol > li {
    margin-bottom: 5px;
  }
  main section.terms dl dd ol > li > ul {
    margin-top: 5px;
  }
  main section.terms dl dd ol > li > ul > li {
    margin-bottom: 10px;
  }
  main section.terms dl dd p.right {
    text-align: right;
    margin-top: 15px;
    padding-right: 10px;
  }
  main section.terms dl dd p {
    line-height: 1.75;
    margin-bottom: 20px;
  }
  main section.terms dl dd p span {
    font-size: 12px;
  }
  main section.terms dl dd p.bold {
    margin-top: 20px;
    font-weight: bold;
  }
  main section.terms dl dd p.title {
    margin-top: 5px;
    margin-bottom: 0;
  }
  main a.lp-link {
    display: block;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 15px;
    font-size: 16px;
    line-height: 1.4;
    text-decoration: none;
    color: #ffffff;
    background-color: #f49416;
  }
  footer {
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #00554c;
    text-align: center;
    font-size: 12px;
    line-height: 1.5;
    color: #ffffff;
  }
  footer a {
    text-decoration: none;
    color: #ffffff;
  }
  .lg-only {
    display: none;
  }
}
#boxer .boxer-loading {
  z-index: 1 !important;
}
