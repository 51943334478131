@color-green-dark: #00897c;
@color-green-light: #afd7d3;
@color-green-footer: #00554c;
@color-green-border: #006e63;
@color-white-pure: #ffffff;
@color-black-light: #333333;
@color-orange: #df6200;
@color-gray: #cccccc;

@path-img: "../img/";
@path-svg: "../img/svg/";

body{
  font-family: "Hiragino Kaku Gothic ProN","メイリオ", sans-serif;
}
@media screen and(min-width: 769px){
  body{
    min-width: 1080px;
  }
  .sm-only{display: none;}
  @import "main/main_lg.less";
}
@media screen and(max-width: 768px){
  @import "main/main_sm.less";
  .lg-only{display: none;}
}

#boxer .boxer-loading {
      z-index:1 !important;
}
