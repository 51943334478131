.feature(@num) {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  margin-bottom: 30px;
  figure {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("@{path-svg}icon@{num}.svg");
    margin-bottom: 15px;
  }
  h3 {
    font-size: 14px;
    line-height: 1.5;
    color: @color-orange;
    text-align: center;
    min-height: 40px;
    margin-bottom: 15px;

  }
  p {
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
  }
}

header.global-header.lg {
  display: none;
}

header.global-header.sm {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  border-bottom: 2px solid @color-green-border;
  background-color: @color-green-dark;
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  z-index: 1;
  .inner {
    width: 100%;
    position: relative;
    a.logo {
      display: block;
      width: 153px;
      height: 30px;
      background-image: url("@{path-svg}logo_white.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left top;
      position: absolute;
      left: 0;
      top: 10px;
    }
    span.btn {
      display: block;
      width: 30px;
      height: 22px;
      position: absolute;
      right: 0;
      top: 15px;
      &:before, &:after {
        display: block;
        content: "";
        width: 30px;
        height: 2px;
        background-color: @color-white-pure;
        border-radius: 5px;
        position: absolute;
        left: 0;
        top: 10px;
        transition-duration: 0.3s;
        transform-origin: center;
        transition-property: transform;
      }
      &:before {
        transform: translateY(10px) rotate(0);
      }
      &:after {
        transform: translateY(-10px) rotate(0);
      }
      figure {
        width: 30px;
        height: 2px;
        border-radius: 5px;
        background-color: @color-white-pure;
        position: absolute;
        left: 0;
        top: 10px;
        transform: scaleX(1);
        transition-duration: 0.3s;
        transform-origin: center;
        transition-property: transform;
      }
      &.open {
        figure {
          transform: scaleX(0);
        }
        &:before {
          transform: translateY(0px) rotate(35deg);
        }
        &:after {
          transform: translateY(0px) rotate(-35deg);
        }
      }
    }
  }
  nav.open {
    height: calc(~'100vh - 50px');
  }
  nav {
    width: 100vw;
    height: 0;
    background-color: @color-green-dark;
    position: relative;
    z-index: 1;
    margin-left: -20px;
    overflow: hidden;
    top: 50px;
    transition-duration: 0.2s;
    ul {
      width: 100%;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 20px;
      li {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid @color-white-pure;
        &:first-child {
          border-top: 1px solid @color-white-pure;
        }
        a {
          display: block;
          text-decoration: none;
          color: @color-white-pure;
          text-align: center;
          font-size: 14px;
          line-height: 50px;
        }
      }
    }

  }
}

main {
  width: 100%;
  padding-top: 60px;
  section.intro {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    h1 {
      margin-bottom: 50px;
      width: 206px;
      height: 40px;
      margin-left: auto;
      margin-right: auto;
      figure {
        width: 100%;
        height: 100%;
        background-image: url("@{path-svg}logo_green.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left top;
      }
    }
    p {
      margin-bottom: 50px;
      font-size: 18px;
      color: @color-green-dark;
      line-height: 1.5;
      text-align: center;
    }
    .link {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 40px;
      margin-bottom: 50px;
      a {
        display: block;
        height: 40px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left top;
        &.app {
          width: 110px;
          background-image: url("@{path-img}btn_app.png");
          margin-right: 20px;
        }
        &.goo {
          width: 134px;
          background-image: url("@{path-img}btn_goo.png");
        }
      }
    }
    figure.visual {
      width: 100%;
      height: calc(~'(100vw - 40px) * 198/335');
      margin-left: auto;
      margin-right: auto;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left top;
      background-image: url("@{path-img}top_visual.jpg");
    }
  }
  section.about {
    width: 100%;
    background-color: @color-green-dark;
    padding-top: 50px;
    padding-bottom: 50px;
    color: @color-white-pure;
    text-align: center;
    .title {
      font-size: 24px;
      margin-bottom: 30px;
    }
    .text {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 30px;
    }
    .lp-link {
      margin-bottom: 20px;

    }
  }
  section.feature {
    width: 100%;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    h2 {
      font-weight: normal;
      font-size: 28px;
      color: @color-green-dark;
      text-align: center;
      margin-bottom: 50px;
    }
    ul {
      font-size: 0;
      li:nth-of-type(2n-1) {
        padding-right: 10px;
      }
      li:nth-of-type(2n) {
        padding-left: 10px;
      }
      li:nth-of-type(1) {
        .feature(1)
      }
      li:nth-of-type(2) {
        .feature(2)
      }
      li:nth-of-type(3) {
        .feature(3)
      }
      li:nth-of-type(4) {
        .feature(4)
      }
      li:nth-of-type(5) {
        .feature(5)
      }
      li:nth-of-type(6) {
        .feature(6)
      }
      li:nth-of-type(7) {
        .feature(7)
      }
      li:nth-of-type(8) {
        .feature(8)
      }
    }
  }
  section.screenshot {
    width: 100%;
    box-sizing: border-box;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: @color-green-light;
    ul.carousel {
      width: calc(~'100vw - 80px');
      height: calc(~'(100vw - 80px) * 595/335');
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
      li.carousel-item {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left top;
        background-color: whitesmoke;
        margin-right: 30px;
        &:nth-of-type(1) {
          background-image: url("@{path-img}screenshot1.jpg");
        }
        &:nth-of-type(2) {
          background-image: url("@{path-img}screenshot2.jpg");
        }
        &:nth-of-type(3) {
          background-image: url("@{path-img}screenshot3.jpg");
        }
        &:nth-of-type(4) {
          background-image: url("@{path-img}screenshot4.jpg");
        }
        &:nth-of-type(5) {
          background-image: url("@{path-img}screenshot5.jpg");
        }
        &:nth-of-type(6) {
          background-image: url("@{path-img}screenshot6.jpg");
        }
      }
      .flickity-page-dots {
        bottom: -45px;
        .dot {
          width: 15px;
          height: 15px;
          background: #666666;
          opacity: 1;
          margin: 0 7px;
        }
        .is-selected {
          background-color: @color-white-pure;
        }
      }
    }
  }
  section.movie {
    h2 {
      font-weight: normal;
      font-size: 28px;
      color: @color-green-dark;
      text-align: center;
      margin-bottom: 50px;
      padding-top: 50px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: 15px;
      margin-right: 15px;
      li {
        width: 100%;
        margin-bottom: 60px;
        img {
          width: 100%;
          height: auto;
          border: 1px solid #CCC;
        }
        p {
          font-size: 14px;
          margin-top: 0.5em;
          text-align: center;
          line-height: 150%;
        }
      }
    }
  }
  section.contact {
    width: 100%;
    background-color: @color-green-dark;
    padding-top: 50px;
    padding-bottom: 50px;
    color: @color-white-pure;
    text-align: center;
    .title {
      font-size: 24px;
      margin-bottom: 30px;
    }
    .text {
      font-size: 14px;
      line-height: 1.5;
    }
    a.mail {
      display: block;
      font-size: 14px;
      line-height: 1.5;
      color: @color-white-pure;
      text-decoration: none;
      margin-bottom: 30px;
    }
    hr{
      width: calc(~'100% - 60px');
      height: 1px;
      margin-left: auto;
      margin-right: auto;
    }
    p.tel{
      font-size: 14px;
      color: @color-white-pure;
      &:before{
        display: inline-block;
        content: "";
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center;
        background-image: url("@{path-svg}tel.svg");
        position: relative;
        top: 2px;
        left: -5px;
      }
    }
  }
  section.terms {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    dl {
      &:nth-of-type(1) {
        margin-bottom: 50px;
      }
      dt {
        font-size: 24px;
        text-align: center;
        margin-bottom: 20px;
      }
      dd {
        width: 100%;
        height: 300px;
        overflow-y: scroll;
        border: 1px solid @color-gray;
        padding: 10px;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 1.5;
        h3{
          font-size: 16px;
          margin-top: 10px;
          margin-bottom: 15px;
          &.center{
            text-align: center;
          }
        }
        h4{
          margin-top: 20px;
          text-decoration: underline;
          margin-bottom: 5px;
        }
        ol{
          padding-left: 20px;
          > li{
            margin-bottom: 5px;
            > ul{
              margin-top: 5px;
              > li{
                margin-bottom: 10px;
              }
            }
          }
        }
        p.right{
          text-align: right;
          margin-top: 15px;
          padding-right: 10px;
        }
        p{
          line-height: 1.75;
          margin-bottom: 20px;
          span{
            font-size: 12px;
          }
          &.bold{
            margin-top: 20px;
            font-weight: bold;
          }
          &.title{
            margin-top: 5px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  a.lp-link{
    display: block;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 15px;
    font-size: 16px;
    line-height: 1.4;
    text-decoration: none;
    color: #ffffff;
    background-color: #f49416;
  }
}

footer {
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: @color-green-footer;
  text-align: center;
  font-size: 12px;
  line-height: 1.5;
  color: @color-white-pure;
  a {
    text-decoration: none;
    color: @color-white-pure;
  }
}
