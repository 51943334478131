.feature(@num) {
  width: 280px;
  min-height: 210px;
  margin-bottom: 50px;
  display: inline-block;
  vertical-align: top;
  figure {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url("@{path-svg}icon@{num}.svg");
  }
  h3 {
    color: @color-orange;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
  }
}

header.global-header.sm {
  display: none;
}

header.global-header.lg {
  width: 100%;
  min-width: 1080px;
  height: 70px;
  background-color: @color-green-dark;
  border-bottom: 3px solid @color-green-border;
  position: fixed;
  z-index: 1;
  .inner {
    width: 100%;
    height: 100%;
    max-width: 1440px;
    min-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-left: 50px;
    padding-right: 50px;
    a.logo {
      display: block;
      width: 257px;
      height: 50px;
      background-image: url("@{path-svg}logo_white.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left top;
    }
    ul {
      display: flex;
      flex-direction: row;
      li {
        margin-left: 45px;
        a {
          display: inline-block;
          color: @color-white-pure;
          text-decoration: none;
          font-size: 16px;
          position: relative;
          &:after {
            display: block;
            content: "";
            width: 100%;
            height: 1px;
            background-color: @color-white-pure;
            position: absolute;
            left: 0;
            bottom: -3px;
            transform: scaleX(0);
            transition-timing-function: ease-in-out;
            transition-duration: 0.2s;
            transition-property: transform;
            transform-origin: center;
          }
          &:hover:after {
            transform: none;
          }
          &.current:after{
            transform: none;
          }
        }
      }
    }
  }
}

main {
  display: block;
  padding-top: 73px;
  section.intro {
    width: 100%;
    h1 {
      width: 350px;
      height: 68px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      padding-top: 50px;
      figure {
        width: 100%;
        height: 100%;
        background-image: url("@{path-svg}logo_green.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left top;
      }
    }
    p {
      font-size: 30px;
      line-height: 1.5;
      color: @color-green-dark;
      text-align: center;
      margin-bottom: 30px;
    }
    .link {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 48px;
      margin-bottom: 70px;
      a {
        display: block;
        height: 48px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left top;
        &:hover {
          opacity: 0.8;
        }
        &.app {
          width: 131px;
          background-image: url("@{path-img}btn_app.png");
          margin-right: 50px;
        }
        &.goo {
          width: 160px;
          background-image: url("@{path-img}btn_goo.png");
        }
      }
    }
    figure.visual {
      width: 642px;
      height: 380px;
      margin-left: auto;
      margin-right: auto;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left top;
      background-image: url("@{path-img}top_visual.jpg");
    }
  }
  section.about {
    width: 100%;
    background-color: @color-green-dark;
    padding-bottom: 50px;
    color: @color-white-pure;
    text-align: center;
    .title {
      font-size: 36px;
      margin-bottom: 30px;
      padding-top: 50px;
    }
    .text {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 20px;
    }
    ul {
      display: flex;
      width: 760px;
      margin-left: auto;
      margin-right: auto;
      justify-content: space-between;
      align-items: center;
      li {
        widows: 48%;
        &:last-child {
          .lp-link {
            margin: 20px 0;
          }
        }
      }
    }
  }
  section.feature {
    width: 100%;
    h2 {
      font-weight: normal;
      font-size: 48px;
      color: @color-green-dark;
      text-align: center;
      margin-bottom: 70px;
      padding-top: 50px;
    }
    ul {
      width: 960px;
      margin-left: auto;
      margin-right: auto;
      font-size: 0;
      li:nth-of-type(3n-1) {
        margin-left: 60px;
        margin-right: 60px;
      }
      li:nth-of-type(1) {
        .feature(1);
      }
      li:nth-of-type(2) {
        .feature(2);
      }
      li:nth-of-type(3) {
        .feature(3);
      }
      li:nth-of-type(4) {
        .feature(4);
      }
      li:nth-of-type(5) {
        .feature(5);
      }
      li:nth-of-type(6) {
        .feature(6);
      }
      li:nth-of-type(7) {
        .feature(7);
      }
      li:nth-of-type(8) {
        .feature(8);
      }
    }
  }
  section.screenshot {
    background-color: @color-green-light;
    padding-top: 100px;
    padding-bottom: 70px;
    ul.carousel {
      width: 1020px;
      height: 533px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
      li.carousel-item {
        width: 300px;
        height: 533px;
        margin-right: 60px;
        background-color: whitesmoke;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left top;
        &:nth-of-type(1) {
          background-image: url("@{path-img}screenshot1.jpg");
        }
        &:nth-of-type(2) {
          background-image: url("@{path-img}screenshot2.jpg");
        }
        &:nth-of-type(3) {
          background-image: url("@{path-img}screenshot3.jpg");
        }
        &:nth-of-type(4) {
          background-image: url("@{path-img}screenshot4.jpg");
        }
        &:nth-of-type(5) {
          background-image: url("@{path-img}screenshot5.jpg");
        }
        &:nth-of-type(6) {
          background-image: url("@{path-img}screenshot6.jpg");
        }
      }
      .flickity-page-dots .dot{
        width: 15px;
        height: 15px;
        background: #666666;
        opacity: 1;
      }
      .flickity-page-dots {
        bottom: -55px;
        .dot {
          width: 15px;
          height: 15px;
          background: #666666;
          opacity: 1;
          margin: 0 7px;
        }
        .is-selected{
          background-color: @color-white-pure;
        }
      }
    }
  }
  section.movie {
    width: 100%;
    h2 {
      font-weight: normal;
      font-size: 48px;
      color: @color-green-dark;
      text-align: center;
      margin-bottom: 70px;
      padding-top: 50px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 1000px;
      margin-left: auto;
      margin-right: auto;
      li {
        width: 23%;
        margin: 0 1% 60px;
        img {
          width: 100%;
          height: auto;
          border: 1px solid #CCC;
        }
        p {
          font-size: 14px;
          margin-top: 0.5em;
          text-align: center;
          line-height: 150%;
        }
      }
    }
  }
  section.contact {
    width: 100%;
    background-color: @color-green-dark;
    padding-bottom: 50px;
    color: @color-white-pure;
    text-align: center;
    .title {
      font-size: 36px;
      margin-bottom: 30px;
      padding-top: 50px;
    }
    .text {
      font-size: 18px;
      line-height: 1.5;
    }
    a.mail{
      display: block;
      font-size: 18px;
      line-height: 1.5;
      color: @color-white-pure;
      text-decoration: none;
      margin-bottom: 30px;
      &:hover{
        text-decoration: underline;
      }
    }
    hr{
      width: 350px;
      height: 1px;
      margin-left: auto;
      margin-right: auto;
    }
    p.tel{
      font-size: 18px;
      color: @color-white-pure;
      &:before{
        display: inline-block;
        content: "";
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center;
        background-image: url("@{path-svg}tel.svg");
        position: relative;
        top: 2px;
        left: -3px;
      }
    }
  }
  section.terms {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    padding-bottom: 80px;
    dl {
      width: 100%;
      &:nth-of-type(1) {
        margin-bottom: 40px;
      }
      dt {
        font-size: 24px;
        margin-bottom: 20px;
        text-align: center;
      }
      dd {
        width: 100%;
        height: 300px;
        box-sizing: border-box;
        border: 1px solid @color-gray;
        font-size: 13px;
        line-height: 1.5;
        padding: 10px;
        overflow-y: scroll;
        h3{
          font-size: 16px;
          margin-top: 20px;
          margin-bottom: 20px;
          &.center{
            text-align: center;
          }
        }
        h4{
          margin-top: 20px;
          text-decoration: underline;
          margin-bottom: 5px;
        }
        ol{
          padding-left: 20px;
          > li{
            padding-left: 10px;
            margin-bottom: 5px;
            > ul{
              margin-top: 5px;
              > li{
                margin-bottom: 5px;
                padding-left: 1.5em;
                text-indent: -1.3em;
              }
            }
          }
        }
        p.right{
          text-align: right;
          margin-top: 15px;
          padding-right: 10px;
        }
        p{
          line-height: 1.75;
          margin-bottom: 20px;
          span{
            font-size: 11px;
          }
          &.bold{
            margin-top: 20px;
            font-weight: bold;
          }
          &.title{
            margin-top: 5px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  a.lp-link{
    display: block;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 15px;
    font-size: 18px;
    line-height: 1.4;
    text-decoration: none;
    color: #ffffff;
    background-color: #f49416;
    opacity: 1;
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    &:hover{
      opacity: 0.7;
    }
  }
}

footer {
  width: 100%;
  min-width: 100%;
  background-color: @color-green-footer;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  color: @color-white-pure;
  a {
    text-decoration: none;
    color: @color-white-pure;
    &:hover{
      text-decoration: underline;
    }
  }
}
